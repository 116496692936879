import { useEffect, useState } from 'react';
import { Toast, ToastBody, ToastTitle, useToastController } from "@fluentui/react-components";
import ViewPort from '../Layout/ViewPort';
import useLocationState from '../../hooks/useLocationState';
import useUser from '../../hooks/useUser';
import { Debug, LoadingAnimation, Submit } from 'ss-lib';
import axios from 'axios';
import { APIErrorHandler } from '../../actions/APIErrorHandler';
import { ssToast } from '../../utils';

const SubmitComponent = props => {
    const { dispatchToast } = useToastController(ssToast);
    const { state } = useLocationState();
    const { user, saveUser, setTitle } = useUser();
    const [file, setFile] = useState();
    const [step, setStep] = useState();
    const [subInfo, setSubInfo] = useState();
    const [fetching, setFetching] = useState(false);
    const [journals, setJournals] = useState();

    useEffect(() => {
        setTitle("Submit a Paper");
        if(window.location.pathname.indexOf("submit") > -1)
            document.title = "ScholarSift - Submit a Paper";

        if(journals === undefined)
            GetJournals();
    })

    const GetJournals = () => {
        if(fetching)
            return ;

        setFetching(true);
        axios.get("/api/reviews", {
                    headers: {
                    Authorization: "Bearer " + user.current.token.id,
                    Coda: user.current.scholarsift.coda,
                    },
                })
                .then(res => {
                    setJournals(res.data);
                    setFetching(false);
                })
                .catch(err => {
                    APIErrorHandler(err, user, saveUser, () => { setFetching(false); GetJournals(); });
                })
    }
    
    const ProcessFile = (f) => {
        var data = new FormData();
        data.append("file", f);
        data.append("fn", f.name);
        axios.post("/api/chksub", data, {
            headers: {
                Authorization: "Bearer " + user.current.token.id,
                Coda: user.current.scholarsift.coda,
            },
        })
        .then(res => {
            if(res.data.processing)
            {
                Clear();
                dispatchToast(<Toast appearance='inverted'><ToastTitle>Notification</ToastTitle><ToastBody>{f.name} is already in the Submission Queue</ToastBody></Toast>, {intent: "info"});
                return ;
            }
            else if(res.data.Submitted) {
                Clear();
                dispatchToast(<Toast appearance='inverted'><ToastTitle>Notification</ToastTitle><ToastBody>{f.name + " " + res.data.Submitted}</ToastBody></Toast>, {intent: "info"});
                return ;
            }
            setSubInfo(res.data);
            setFile(f);
            setStep("submitItemProps");
        })
        .catch(err => { 
            APIErrorHandler(err, user, saveUser, () => { ProcessFile(f) });
        })
    }

    const SelectFile = e => {
        dispatchToast(<Toast appearance='inverted'><ToastTitle>Notification</ToastTitle><ToastBody>Checking {e.target.files[0].name}</ToastBody></Toast>, {intent: "info"});
        ProcessFile(e.target.files[0]);
    }
    
    const onDrop = e => {
        dispatchToast(<Toast appearance='inverted'><ToastTitle>Notification</ToastTitle><ToastBody>Checking {e.dataTransfer.files[0].name}</ToastBody></Toast>, {intent: "info"});
        ProcessFile(e.dataTransfer.files[0]);
    }

    const Clear = () => {
        setFile(undefined)
        setStep(undefined)
        setSubInfo(undefined)
        setFetching(false)
    }

    const DoSubmit = (data) => {
        //Prep Form Data
        var md = {...data};
        delete md.cover;
        const { authors } = md;
        var d = new FormData();
        if(data.cover)
            d.append("cover", data.cover);
        for(var i = 0; i < authors.length; i++) {
            if(authors[i].cv && typeof authors[i].cv !== "string") {
                d.append("cv-" + i, authors[i].cv);
                delete authors[i].cv;
            }
        }
        d.append("metadata", JSON.stringify(md));
        
        //Send & Process
        axios.post("/api/submit", d, {
            headers: {
                Authorization: "Bearer " + user.current.token.id,
                Coda: user.current.scholarsift.coda,
            },
        })
        .then(res => {
            Clear();

            //Toast
            dispatchToast(<Toast appearance='inverted'><ToastTitle>Notification</ToastTitle><ToastBody>You have successfully submitted {data.title}</ToastBody></Toast>, {intent: "success"}) 
            
        })
        .catch(err => { 
            APIErrorHandler(err, user, saveUser, () => { DoSubmit(data) });
        })
    }

    if(!Debug())
        return <div
                    className="submission-fill"
                    style={{
                    height: state.viewport.h - (state.viewport.f + state.viewport.m),
                    }}
                >
                    <div
                    className="loading"
                    style={
                        !props.management
                        ? {
                            height:
                                state.viewport.h - (state.viewport.f + state.viewport.m),
                            }
                        : undefined
                    }
                    >
                    We're signing up Law Reviews now, and are excited to show you this
                    feature in the near future.
                    </div>
                </div>

    if(Debug()) {
        console.log(subInfo)
        console.log(step)
        console.log(file)
    }

    return  props.unwatched === undefined ? 
            <ViewPort>
                <div style={{ width:"80vw", minWidth:"400px", margin: "auto", minHeight: state.viewport.h - (state.viewport.m + state.viewport.f)}} >
                    <Submit 
                        h={state.viewport.h - (state.viewport.m + state.viewport.f)}
                        item={subInfo}
                        file={file}
                        journals={journals}
                        profile={user.current ? user.current.scholarsift : undefined}
                        step={step}
                        Clear={Clear}
                        DoSubmit={DoSubmit}
                        SelectFile={SelectFile}
                        onDrop={onDrop}
                    />
                </div>
            </ViewPort> :
            <Submit 
                item={subInfo}
                file={file}
                journals={journals}
                profile={user.current ? user.current.scholarsift : undefined}
                step={step}
                Clear={Clear}
                DoSubmit={DoSubmit}
                SelectFile={SelectFile}
                onDrop={onDrop}
            />
}

export default SubmitComponent;